import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesRed: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Red Hood guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_red.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Red Hood guide & review</h1>
          <h2>A guide & review for Red Hood in NIKKE: Goddess of Victory.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Banner" />
        <StaticImage
          src="../../../images/nikke/generic/banner_red.jpg"
          alt="Tia"
        />
        <p>
          <strong>Red Hood</strong> banner will be available from{' '}
          <strong>11/02 05:00 ~ 11/23 23:59 (UTC+9).</strong>
        </p>
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
        </div>
        <p>
          A formidable girl with an eventful past, Red Hood is an experienced
          soldier in combat. Her luminous hair and ardent eyes were testaments
          to her incredible performance in the battlefield. She takes the role
          of a relentless sniper, geared with a unique Burst Skill that can be
          activated in any burst stage. Depending on one's choice, she can
          provide either massive offensive buffs or defensive buffs. She also
          possesses the exclusive ability to convert excess charge speed into
          charge damage, allowing her to utilize all sorts of buffs to their
          maximum efficacy. In the right team, Red Hood is the incarnation of
          destruction itself or, if she chooses to let her allies bring the
          glory instead, she transforms into a guardian of fortitude, empowering
          her allies from the backline.
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          Red Hood is a Pilgrim SR Attacker regarded as a superior version of
          Alice and share similar functions. Unlike her childish counterpart,
          however, Red Hood is gifted with a permanent pierce at the cost of no
          automatic lifesteal. Her Skill 1 is about providing herself a
          stackable charge speed buff, whose excess would convert to charge
          damage at a rate of 240%. Her Skill 2, on the other hand, is about
          augmenting her Burst Skill with extra effects. You can assume that her
          Skill 2 and Burst Skill are one skill that gets split, forcing players
          to invest double the number of skill books needed originally. Last but
          not least, her unique Burst Skill is what distinguishes her from the
          rest of the roster. She can use it in Stage I, II, and III, depending
          on what kind of assistance you need from her.
        </p>
        <ul>
          <li>
            Using her Burst Skill in Stage I would allow her to heavily
            strengthen your team.
          </li>
          <li>
            Using her Burst Skill in Stage II would allow her to taunt all
            enemies for a long time while continuously regenerating HP with the
            help of lifesteal and potency.
          </li>
          <li>
            Using her Burst Skill in Stage III would transform her into a
            goddess of annihilation. Testing shows that she potentially has the
            highest DPS in-game.
          </li>
        </ul>
        <p>
          Additionally, no matter in which stage you activate her Burst, it will
          always go on a 40s CD, unless reset by the gimmick from the Stage I
          and II versions, which only happens once per battle (the reset limit
          is separate for both stages). In some teams, these bonus resets can
          mean a slight increase in damage and survivability. Still, the 40s CD
          is a big letdown for everyone trying to use her in the B1 position as
          an ATK buffer.
        </p>
        <Alert variant="primary" className="alert-update">
          <p>
            Since the resets of Burst Skill in Stage I and II are separate, it
            is possible to use her Burst Skill in all stages in one rotation.
          </p>
        </Alert>
        <p>Here are some ways to use her:</p>
        <ul>
          <li>
            You can use Red Hood as a DPS or a buffer. This highly depends on
            the enemy. For instance, against multiple mobs or a boss with
            multiple parts, Red Hood can quickly accrue tons of damage. However,
            when her teammates are capable of dealing damage more efficiently
            than her, consider using her as a buffer.
          </li>
          <li>
            If you are using her as a DPS, focus on activating her Burst Skill
            in Stage III, which revolves around landing a series of charged
            shots on one or more targets. In one way, it behaves similarly to
            Alice's but has unlimited ammo and a higher fire rate when firing in
            AUTO. While the theoretical damage per shot is lower compared to
            Alice's, and skilled players can shoot much faster with Alice, Red
            Hood is not gated by ammo and is also not reliant on the player's
            tapping/clicking speed. It also has a larger pierce radius, allowing
            her to simultaneously hit nearby targets much more easily. For
            starters, this means that Red Hood, under most circumstances, will
            have much higher DPS than Alice. She is in fact the best DPS and the
            best unit in game right now.
          </li>
          <ul>
            <li className="highlight">
              She can activate her Burst Skill in Stage I once without ruining
              the rotation, which grants the entire team an enormous ATK buff.
              Use it to slightly boost your damage dealt, but make sure to
              evaluate the damage in hindsight.
            </li>
          </ul>
          <li>
            In cases where she would perform better as a support, focus on using
            her Burst Skill in Stage I to maximize the damage of other units,
            particularly units who can deal insane damage in short bursts, such
            as SAnis, Snow White, Maxwell, Alice, and so on. Why? Because it is
            a massive ±77% caster's ATK buff (at level 10) that repeats every
            40s instead of 20s. Even better, attackers naturally have high base
            ATK, making the empowerment as effective for your defenders as it is
            for your other attackers. That said, you need to ensure that the
            activation timing of the Burst Skill of those major units
            synchronizes with her Burst Skill. Analyze your formation and find
            out which rotation results in the highest amount of damage.
          </li>
          <ul>
            <li className="highlight">
              After the second activation of Burst Skill in Stage I, activating
              it again will incur a 40s CD. If you want a smooth burst rotation,
              you need to bring another B1 with you, whether 20s or 40s. This is
              normally a flex slot, so you can bring whoever you want here.
              Sakura, Milk, and Noise are awesome choices, but Pepper or SMary
              can fill the spot too. In campaign stages, you may also choose to
              wait out the 40s cooldown by leaving one mob alive before boss
              appears.
            </li>
          </ul>
          <li>
            Lack of survivability? In desperate situations, Red Hood can
            activate her Burst Skill in Stage II to taunt all enemies and grant
            herself lifesteal and HP potency. The idea behind this skill is Red
            Hood taking damage for her allies and outhealing it. If the damage
            is too much, she would still die, though. The first activation of
            Burst Skill in Stage II will have a CD reset, which means it will
            barely influence your primary rotation. Subsequent activations will
            incur a 40s CD, which can lead to DPS loss since you can now no
            longer use it in Stage I or III.
          </li>
          <ul>
            <li className="highlight">
              For her lifesteal to be optimal, you need Red Hood to activate her
              special mode locked behind Burst Skill in Stage III. The problem
              is: this is only possible with CD reset, otherwise there will be a
              40s CD after activating Burst Skill in Stage II. Thankfully, this
              is not a strict requirement. Red Hood can still heal enough with
              only her lifesteal (without Burst iII), but overall she will still
              heal less per second.
            </li>
          </ul>
        </ul>
        <SectionHeader title="Usage Analysis" />
        <p>
          Red Hood is a prominent character in various game modes. She is a
          GODDESS. She is versatile and can be used everywhere (think of her as
          modern Scarlet). The comfort of not having to fast-click in Burst also
          makes her very beginner-friendly and campaign-friendly.
        </p>
        <h5>Campaign</h5>
        <p>
          She has a proven value in campaign pushing as a DPS or as a support.
          As a DPS, she is just Alice but much easier to use (no need to manual
          fire during Burst). Most stages have Alice as one of the lowest CP
          clearers, but Red Hood can also be one too if the circumstances are on
          her side. Like Alice, she provides incredible burst generation,
          demonstrates strong clearing potential during Burst Skill, and can
          even strengthen the team for 10s in one (or more) rotations. Her
          larger radius comes into play here with her hitting more than one mob
          at a time, something that Alice may struggle to achieve. As a support,
          her buff in B1 is influential in stages that can be cleared within two
          or three rotations (read below). This supportive side of her is really
          powerful for late-game pushing with high CP penalty.
        </p>
        <h5>Bossing</h5>
        <p>
          A must-have monster DPS. Outstanding single target damage that
          multiplies when the boss is weak to pierce or sports multiple parts.
          She also does not care if the boss spawns mobs in front of it because
          she can hit all of them at the same time with her Burst Skill. She
          should have more or less the same damage as Alice with a chance of
          being higher or lower depending on their investments and boss element.
          If Alice was considered to be the best DPS, Red Hood now takes over
          her spot.
        </p>
        <p>
          After the recent Burst Skill buff, we personally do not think that Red
          Hood would ever be used a support when bossing. However, we could
          foresee the possibility of her being used in SAnis team, which
          currently does not pair well with most ATK buffs due to the inherent
          max ammo buff (Liter, for example). She also does not actually pair
          well with Dorothy's part damage because shotguns are inaccurate (and
          her S2 is untargettable). Red Hood being able to provide an enormous
          ATK buff every time SAnis bursts without any repercussions is
          something to look forward to. In fact, SAnis is a support, which means
          Red Hood's ATK buff will be 1.2x more effective on her than on
          ordinary attackers.
        </p>
        <Alert variant="primary" className="alert-update">
          <p>
            Against bosses in general, Red Hood needs to be in a 2-1-2 formation
            if used as a support in scenarios where you are to burst 3 or more
            times. This may mean loss of DPS, but some teams are forced to run
            with 2 DPS regardless. SAnis is a flexible unit that is often paired
            with 1 other DPS (2 if healing is useless), so pairing her and Red
            Hood is most likely not going to change anything.
          </p>
        </Alert>
        <h5>PVP</h5>
        <p>
          The most hated game mode yet also the most loved, PvP is where Red
          Hood may belong to. No, she is not meta-defining, but she surely has
          something to offer. In many ways, she is just superior to Alice (DPS)
          and Liter (support), but there is a catch. Her being able to Burst I,
          II, and III may require thoughtful positioning of units. Not only
          that, her shortcomings match Alice's perfectly. Since she only hits
          one unit at a time during Burst and not two like we expected, she is
          easily countered by any well-placed defender (especially with Biscuit)
          and some luck.
        </p>
        <p>
          Red Hood boasts a strong buff in B1 that comes with a 40s CD, but that
          is not a problem in PvP. That buff is significantly better than
          Liter's, and she herself provides higher burst generation thanks to
          her unconditional pierce and quick charging time. She also does not
          feed Jackal. Consider pairing her with units like Scarlet, Maiden,
          Privaty, Harran, or even SAnis to instill fear in your rivals. She can
          also taunt, but don't expect it to be useful unless you use her Burst
          Skill in Stage III too.
        </p>
        <h5>Pilgrim Tower</h5>
        <p>
          A valuable unit in Pilgrim Tower. Like Alice, she will always find a
          spot in any stages that resemble campaign. The ability to provide a
          teamwide ATK buff over Dorothy's brand is always appreciated, not to
          mention her high burst generation. Often, her burst may also be
          preferred over Scarlet's or Mod's. Since you will be investing in her
          outside Pilgrim Tower anyway, you do not need to invest anything to
          enjoy the CP boost.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Investments" />
        <p>
          She is a cheaper version of Alice and is usable at lower investments,
          but her damage scales rapidly with higher skill levels and better OL
          lines. Unlike Red Hood, Alice requires both skill and gear investments
          to even be remotely usable. This is one strength Red Hood has over
          Alice, especially for beginners. This means you can start using her
          early and slowly invest in her as you progress.
        </p>
        <h5>Gear Investments</h5>
        <p>
          As usual, start with Helmet, Gloves, and Body. You want to overload
          her Boots too to enjoy the OL lines. We personally recommend maxing
          her Helmet and Gloves to Level 5 and leaving her Body at Level 3
          because of the lower ATK scaling. The Boots can be left at Level 0
          unless you need the CP boost, in which case Level 3 is sufficient. For
          the OL lines, prioritize 1+ Max Ammo and ATK. Elemental Damage is
          useful situationally. Then, for the extra lines, you can choose
          between 2 paths:
        </p>
        <ul>
          <li>
            Full Charge Route: Low Max Ammo + Charge Speed, combined with
            Bastion
          </li>
          <li> Quick Scope Route: High Max Ammo, combined with Resilience</li>
        </ul>
        <p>
          The charge speed variant has a higher damage ceiling and scale
          exponentially with other charge speed buffers. We suggest going with
          the first one you get, otherwise you will be spending too much crystal
          on her.
        </p>
        <Alert variant="primary" className="alert-update">
          <p>
            Note that Red Hood's Burst Skill benefits from Charge Speed OL and
            Charge Damage OL equally. The value of Charge Damage OL is actually
            2.4x the value of Charge Speed of the same level. The reason why
            Charge Speed is preferred is due to off-burst damage.
          </p>
        </Alert>
        <h5>Skill Investments</h5>
        <p>
          Speaking of priorities, her Burst Skill contributes to a major portion
          of her total damage, and her S2 is more important than her S1. Still,
          as a DPS, she requires all skills to be leveled up. Meanwhile, as a
          Support, she only needs her Burst Skill to be upgraded. Regardless, we
          recommend upgrading her with DPS and Support capabilities in mind.
        </p>
        <p>
          Start with 5/5/5 (or 4/4/4), then branch to 5/7/7, then 7/7/7, then
          7/10/10. This is decent enough, but 10/10/10 is the end goal.
        </p>
        <h5>Cube Investments</h5>
        <p>
          If Max Ammo is less than 10, Resilience is best. If Max Ammo is equal
          to or more than 10, both Resilience and Bastion can be used (in which
          case, we recommend using the higher level one for the tiny but
          influential base ATK + elemental damage boost). Adjutant's value is
          too small to justify using it, but it is still the best alternative
          you have got.
        </p>
        <SectionHeader title="Team Compositions" />
        <p>
          Red Hood's team formation depends on whether she is used as a DPS or
          as a support:
        </p>
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-red">Burst 1-3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          Pretty much the basic setup for all damage dealers out there, the most
          comfortable and the most powerful. Red Hood comes equipped with a high
          amount of ATK buff already, so Damage Taken buffs are much more
          effective. She can also use her Burst Skill in Stage I once in place
          of Liter. However, since her ammo in Burst is unlimited, she does not
          benefit much from Liter + Noir combo. That aside, this team can be
          used both in campaign and in bossing.
        </p>
        <p>
          If a stage can be cleared within 2 or fewer burst rotations, you can
          choose to bring a 40s or 60s CD Burst I unit to replace Liter instead.
        </p>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-red">Burst 1-3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
        </div>
        <p>
          Another alternative to the Blanc variant — Maxwell is the alleged BiS
          support for bossing, but deploy more viable DPS for campaign pushing
          (such as Scarlet/Modernia). Tia provides an ATK Damage buff which
          functions similarly to Damage Taken. Against bosses, Dorothy can also
          be inserted to further stack Parts Damage on top. Maxwell provides Red
          Hood with ATK buff as well as Charge Speed, which converts into Charge
          Damage during her burst. Maxwell also profits well from the Tia Naga
          setup.
        </p>
        <h5>Team #3</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-red">Burst 1-3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
        </div>
        <p>
          If a stage can be cleared within 3 or fewer burst rotations, you can
          choose to bring a 40s or 60s CD Burst I unit (eg Rapunzel for heals)
          to replace the B1-CDR unit. Alternatively, you can add another B3
          instead. In this 1-1-3 team, Red Hood will act as a support buffer,
          replacing the B1-CDR. Do Tia -&gt; Red Hood rotation 2 times, and
          after that, you're left with no burst rotation for 40s. Try to keep a
          small rapture alive and allow ~40 seconds to elapse so that Red Hood
          can Burst again. Kill the small rapture and now proceed to the boss
          wave and try to kill it with the insane Tia + RedHood + Naga buff
          chain for your B3 units.
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="wun4BUbdh40" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="EKcfOGNOdVA" />
          </Col>
        </Row>
        <h5>Team #4</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-red">Burst 1-3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="sakura" enablePopover />
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
              <NikkeCharacter mode="icon" slug="milk" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="placeholder-box">B2</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
        </div>
        <p>
          Red Hood as a support is underrated. She provides an enormous ATK buff
          that can easily be synchronized with units reliant on major Burst
          Skills like SAnis. Moreover, her ATK buff is based on the caster's
          ATK, which makes it as effective for defenders and supports as it is
          for attackers. Other B1 can replace the suggested trio. Remember to
          include a CDR.
        </p>
        <h5>Team #5</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-red">Burst 1-3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="snow-white" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
        </div>
        <p>
          The new Special Interception one-shot team. Previously, Red Hood used
          to be Miranda. Now, she will take over her spot. Period.
        </p>
        <SectionHeader title="Should You Pull" />
        <p>
          You might as well quit the game if you fail to obtain her. She is
          currently THE BEST UNIT IN THE GAME. Obtain at least one copy,
          preferable using Mileage (or obtained for free from daily pulls), but
          Limit Breaks are extremely valuable. As she is a Pilgrim, should you
          miss the special banner, you will have a hard time getting her again
          in the future.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>BEST UNIT IN GAME with INSANE DPS</li>
                  <li>
                    The only unit who can use Burst Skill in any Burst Stages
                  </li>
                  <li>Can act as a DPS or a buffer</li>
                  <li>Can convert excess charge speed into charge damage</li>
                  <li>
                    Unconditional pierce, allowing her to deal much more damage
                    against clustered mobs and parts
                  </li>
                  <li>
                    Possesses the highest ATK buff in Burst I despite the 40s CD
                  </li>
                  <li>
                    Large pierce radius; hitting multiple mobs in close
                    proximity has never been easier
                  </li>
                  <li>
                    Has a taunt, a lifesteal, and HP potency for emergencies
                  </li>
                  <li> Unlike Alice, she is usable at lower investments</li>
                  <li> Endless campaign and Pilgrim Tower potential</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Burst Skill has a 40s CD even when used in Burst Stage I or
                    II (except for the first activation)
                  </li>
                  <li>
                    Her Burst Skill in Stage II requires her to also use Burst
                    Skill in Stage III in the same rotation, else it may
                    backfire if healing is lower than damage taken
                  </li>
                  <li>
                    The zoomed-in scope during Burst Skill may make it hard to
                    spot out-of-reach enemies if manually controlled
                  </li>
                  <li> Skill investment dependent</li>
                  <li>
                    Pilgrim unit; obtaining outside special banner is
                    luck-dependent
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <p>
          Because Red Hood is an unique character and she can use her Burst in
          every stage, we decided to rate her according to how you can use her -
          so as main B1, B2 or B3 character.
        </p>
        <h5>B1</h5>
        <div className="ratings-container">
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Story (Early)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Story (Late)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>PVP</p>
          </div>
        </div>
        <h5>B2</h5>
        <div className="ratings-container">
          <div className="ratings-box c">
            <h6>C</h6>
            <p>Story (Early)</p>
          </div>
          <div className="ratings-box c">
            <h6>C</h6>
            <p>Story (Late)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box c">
            <h6>C</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>PVP</p>
          </div>
        </div>
        <h5>B3</h5>
        <div className="ratings-container">
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Story (Early)</p>
          </div>
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Story (Late)</p>
          </div>
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesRed;

export const Head: React.FC = () => (
  <Seo
    title="Red Hood guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Red Hood in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
